import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/index.scss";
import "./assets/fonts/Inter/Inter-Regular.ttf";
import ScrollToTop from "./helpers/ScrollToTop";
import BasketContext from "./helpers/Contexts/basketContext";
import UserInfoContext from "./helpers/Contexts/userInfoContext";

const Main = () => {
  const [basket, setBasket] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [theme, setTheme] = useState("swamp");
  document.querySelector("body").setAttribute("data-theme", theme);

  return (
    // <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <BasketContext.Provider value={{ basket, setBasket }}>
        <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
          <App />
        </UserInfoContext.Provider>
      </BasketContext.Provider>
    </BrowserRouter>
    // </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
