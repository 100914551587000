import React, { useState } from "react";
import s from "./styles.module.scss";
import useMediaQuery from "../../hooks/useMediaQuery";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import SearchInput from "../../components/SearchInput/SearchInput";
import ImgNuts from "../../assets/images/catalog/catalogNuts.png";
import ImgSuccade from "../../assets/images/catalog/catalogSuccade.png";
import ImgDried from "../../assets/images/catalog/catalogDried.png";
import ImgSet from "../../assets/images/catalog/catalogSet.png";
import ImgSweets from "../../assets/images/catalog/catalogSweets.png";
import { Link } from "react-router-dom";
import categoriesInfo from "../../assets/categoriesInfo.json";
import ExportData from "../../components/ExportData/ExportData";

const Catalog = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [blurWrap, setBlurWrap] = useState(false);

  return (
    <main className={s.catalog}>
      {isDesktop && <BreadCrumbs />}
      <SearchInput setBlurWrap={setBlurWrap} blurWrap={blurWrap} />
      <div className={`${s.content} ${blurWrap && s.searchBlur}`}>
        <Link to="/catalog/nuts">
          <div className={`${s.block}`}>
            <div className={s.text}>
              <h3>{categoriesInfo[0].title}</h3>
              <p>{categoriesInfo[0].text}</p>
            </div>
            <img src={ImgNuts} alt="nuts" />
          </div>
        </Link>
        <Link to="/catalog/dry">
          <div className={`${s.block}`}>
            <div className={s.text}>
              <h3>{categoriesInfo[1].title}</h3>
              <p>{categoriesInfo[1].text}</p>
            </div>
            <img src={ImgDried} className={s.contain} alt="dried" />
          </div>
        </Link>
        <Link to="/catalog/sweets">
          <div className={`${s.block} `}>
            <div className={s.text}>
              <h3>{categoriesInfo[2].title}</h3>
              <p>{categoriesInfo[2].text}</p>
            </div>
            <img src={ImgSweets} alt="sweets" />
          </div>
        </Link>
        <Link to="/catalog/succade">
          <div className={`${s.block}`}>
            <div className={s.text}>
              <h3>{categoriesInfo[3].title}</h3>
              <p>{categoriesInfo[3].text}</p>
            </div>
            <img src={ImgSuccade} alt="succade" />
          </div>
        </Link>

        <Link to="/catalog/sets">
          <div className={`${s.block}`}>
            <div className={s.text}>
              <h3>{categoriesInfo[4].title}</h3>
              <p>{categoriesInfo[4].text}</p>
            </div>
            <img src={ImgSet} alt="sets" />
          </div>
        </Link>
      </div>
      {/* <ExportData/> */}
    </main>
  );
};

export default Catalog;
