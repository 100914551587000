import React, { useContext, useEffect, useState } from "react";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import s from "./styles.module.scss";
import ImgTg from "../../assets/images/menu/tg-icon.png";
import ImgVk from "../../assets/images/menu/vk-icon.png";
import ImgWa from "../../assets/images/menu/wa-icon.png";
import deliveryAreas from "../../assets/deliveryAreas.json";
import UserInfoContext from "../../helpers/Contexts/userInfoContext";
import styled from "styled-components";

const Delivery = () => {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const [pickedLocation, setPickedLocation] = useState(-1);

  useEffect(() => {
    setPickedLocation(userInfo.location);
  }, [userInfo.location]);
  return (
    <main>
      <BreadCrumbs />
      <div className={s.contacts}>
        <a className={s.socialBlock} href="tel:+79933382402">
          Телефон для связи: <span className={s.textBold}>+7 993 338 24 02</span>
        </a>

        <a className={s.socialBlock} href="https://t.me/+79933382402" target="_blank" rel="noopener noreferrer">
          <span>Telegram: </span>
          <img src={ImgTg} alt="tg" />
        </a>

        <a className={s.socialBlock} href="https://vk.com/belochka_oreshek" target="_blank" rel="noopener noreferrer">
          <span>Группа VKонтакте: </span>
          <img src={ImgVk} alt="vk" />
        </a>

        <a className={s.socialBlock} href="https://wa.me/+79933382402" target="_blank" rel="noopener noreferrer">
          <span>WhatsApp: </span>
          <img src={ImgWa} alt="wa" />
        </a>
      </div>

      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae6de0191fcd79f21b43eadca90a73bf78de3c8e4bdb42a4941250827599daa95&amp;source=constructor"
        width="100%"
        height="380"
      ></iframe>
      <div className={s.content}>
        <div className={s.contentMain}>
          {deliveryAreas
            .filter((area) => area.price.delivery > 0)
            .map((item, index) => (
              <AreaBlock
                borderColor={item.color}
                className={index === pickedLocation && "active"}
                key={index}
                onClick={() => {
                  setUserInfo({ ...userInfo, location: index });
                }}
              >
                <h2>{item.name}</h2>
                <p>При заказе от {item.price.free} руб - бесплатно</p>
                <p>В остальных случаях - {item.price.delivery} руб</p>
              </AreaBlock>
            ))}
        </div>
        <div className={s.contentAside}>
          <div className={s.block}>
            <AreaBlock noclick>
              <h2>Доставка в регионы России и СНГ</h2>
              <p>
                Доставка Вашего заказа осуществляется через Транспорную компанию или Почту, подробности уточняйте у менеджера через{" "}
                <a href="https://wa.me/+79933382402" target="_blank" rel="noopener noreferrer">
                  WhatsApp
                </a>{" "}
                или{" "}
                <a href="https://t.me/+79933382402" target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </p>
            </AreaBlock>
          </div>
        </div>
      </div>
    </main>
  );
};

const AreaBlock = styled.div`
  background: var(--firstLight);
  border-radius: 9px;
  text-align: center;
  padding: 16px;
  cursor: ${(props) => (props.noclick ? "initial" : "pointer")};
  &.active {
    position: relative;
    outline: 3px solid ${(props) => `${props.borderColor}` || "#e07c2a"};
    &::after {
      content: "";
      position: absolute;
      right: -1px;
      top: -1px;
      width: 40px;
      height: 40px;
      background-color: ${(props) => `${props.borderColor}` || "#e07c2a"};
      clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 100% 100%);
      border-radius: 0 9px 0 0;
    }
  }
  a {
    text-decoration: underline;
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.6875rem;
    margin-bottom: 0.375rem;
    word-break: break-word;
  }
  p {
    color: var(--secondDark);
    font-size: 0.9375rem;
    font-weight: 500;
    line-height: 1.375rem;
  }
`;

export default Delivery;
