import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Catalog from "./pages/Catalog/Catalog";
import Home from "./pages/Home/Home";
import ItemDetails from "./pages/ItemDetails/ItemDetails";
import Basket from "./pages/Basket/Basket";
import Delivery from "./pages/Delivery/Delivery";
import CatalogCategory from "./pages/CatalogCategory/CatalogCategory";
import Layout from "./pages/Layout/Layout";
import Header from "./components/Header/Header";
import { useCallback, useContext, useEffect, useState } from "react";
import { sendMetrik } from "./helpers/YaMetrics";
import BasketContext from "./helpers/Contexts/basketContext";
import PageDescrContext from "./helpers/Contexts/pageDescrContext";
import Referrals from "./helpers/Referrals";
import { SetPageDescription } from "./helpers/SetPageDescription";
import PageNotFound from "./pages/PageNotFound/PageNotFound ";
import UserInfoContext from "./helpers/Contexts/userInfoContext";

function App() {
  let location = useLocation();
  const [pagesDescr, setPagesDescr] = useState({ title: "", description: "", image: "" });
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo")) : { location: -1, payment: 0 };
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const storedBasket = JSON.parse(localStorage.getItem("basket")) ? JSON.parse(localStorage.getItem("basket")) : [];
  const { basket, setBasket } = useContext(BasketContext);
  const [isVisible, setIsVisible] = useState(!document.hidden);

  useEffect(() => {
    sendMetrik("hit", location.pathname);
  }, [location]);

  // Localstorage
  const handleVisibility = useCallback(() => {
    setIsVisible(!document.hidden);
  }, []);

  useEffect(() => {
    localStorage.setItem("basket", JSON.stringify(basket));
  }, [JSON.stringify(basket)]);

  useEffect(() => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }, [JSON.stringify(userInfo)]);

  useEffect(() => {
    SetPageDescription(pagesDescr.title, pagesDescr.description, pagesDescr.image);
  }, [JSON.stringify(pagesDescr)]);

  useEffect(() => {
    setBasket(storedBasket);
    setUserInfo(storedUserInfo);
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibility);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, [handleVisibility]);
  //

  return (
    <>
      <PageDescrContext.Provider value={{ pagesDescr, setPagesDescr }}>
        <Header />
        <Referrals />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="catalog" element={<Catalog />} />
            <Route path="catalog/:categoryName" element={<CatalogCategory />} />
            <Route path="basket" element={<Basket />} />
            <Route path="delivery" element={<Delivery />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </PageDescrContext.Provider>
    </>
  );
}

export default App;
