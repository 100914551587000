import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import ButtonBig from "../../components/ButtonBig/ButtonBig";
import ItemPreview from "../../components/ItemPreview/ItemPreview";
import Loader from "../../components/Loader/Loader";
import s from "./styles.module.scss";

const CatalogCategory = () => {
  const { categoryName } = useParams();
  const [categoriesItems, setCategoriesItems] = useState([]);
  const [categoryFullName, setCategoryFullName] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);

  async function getCategoryList() {
    const urlFullData = "https://63fb9b597a045e192b6c5006.mockapi.io/nuts";
    const items = await fetch(urlFullData, { method: "GET" });
    setCategoryLoading(false);
    const itemsJson = await items.json();
    const filter = itemsJson.filter((item) =>
      categoryName === "popular"
        ? item.popular === true
        : item.type === getCategoryId(categoryName)
    );

    filter.sort((a, b) => {
      const fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();
      return fa.localeCompare(fb, undefined, {
        // undef для того чтоб была локаль браузера
        numeric: true, // верно распознает числа
        sensitivity: 'base' // делает не чуствительным к регистру
      });
    });// -1
    setCategoriesItems(filter);
  }

  const getCategoryId = (category) => {
    if (category === "nuts") {
      return 1;
    }
    if (category === "dry") {
      return 2;
    }
    if (category === "sets") {
      return 3;
    }
    if (category === "sweets") {
      return 4;
    }
    if (category === "succade") {
      return 5;
    }
    if (category === "popular") {
      return 100;
    }
    return 0;
  };

  const getCategoryNamebyId = (category) => {
    switch (category) {
      case 1:
        return "Орешки";
      case 2:
        return "Сухофрукты";
      case 3:
        return "Подарочные наборы";
      case 4:
        return "Сладости";
      case 5:
        return "Цукаты";
      case 100:
        return "Популярные товары";
      default:
        return "";
    }
  };

  useEffect(() => {
    getCategoryList(categoryName);
    setCategoryLoading(true);
    setCategoryFullName(getCategoryNamebyId(getCategoryId(categoryName)));
  }, []);

  if (categoryLoading) {
    return <Loader />;
  }
  return (
    <main className={s.categories}>
      <BreadCrumbs />
      {categoriesItems.length > 0 ? (
        <>
          <h2>{categoryFullName}</h2>
          <div className={s.list}>
            {categoriesItems.map((item) => {
              return (
                <Link key={item.id} to={`/item/${item.id}`}>
                  <ItemPreview item={item} />
                </Link>
              );
            })}
          </div>
        </>
      ) : (
        <h2>Товаров в выбранной категории пока нет</h2>
      )}

      <Link to="/catalog">
        <ButtonBig empty="true" center="true">
          Перейти в каталог
        </ButtonBig>
      </Link>
    </main>
  );
};

export default CatalogCategory;
