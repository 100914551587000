import React from "react";
import styled from "styled-components";

const PageNotFound = () => {
  return (
    <NotFound>
      <p>Страница не найдена</p>
    </NotFound>
  );
};

export default PageNotFound;

const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 100px);
  padding: 20px 0;
  p {
    font-size: 20px;
    font-weight: 600;
  }
`;
