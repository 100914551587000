import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useEffect } from "react";
import referrersList from "../assets/referrersList.json";

export default function Referrals() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsFocus = "ref";
  const hasRef = searchParams.has(paramsFocus);
  const telegram = {
    token: process.env.REACT_APP_TG_TEST_CHAT_TOKEN,
    chat: process.env.REACT_APP_TG_TEST_CHAT_NUMBER,
  };

  const getBrowserInfo = () => {
    let OSName = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
    if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
    if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
    const isMobile = navigator.userAgentData.mobile ? "Телефон" : "Комп";
    return OSName + ", " + isMobile;
  };

  const getRefMessage = (linkRef) => {
    // const timeNow = moment().format("MM/DD/YY hh:mm A");
    const extraInfo = getBrowserInfo();
    console.log(location, "location");
    const referralIndex = referrersList.findIndex((ref) => ref.idLink == linkRef);
    let referral = { idLink: "no", name: "Неизвестный" };
    if (referralIndex >= 0) {
      referral = referrersList[referralIndex];
    }
    let message = `По ссылке реферала ${referral.name} перешли еще раз \%0A`;
    message += `${extraInfo} \%0A`;
    return message;
  };
  const sendRefDataTg = (referral) => {
    fetch(`https://api.telegram.org/bot${telegram.token}/sendMessage?chat_id=${telegram.chat}&text=${getRefMessage(referral)}`, {
      method: "GET",
    });
  };

  useEffect(() => {
    if (hasRef) {
      if (!JSON.parse(localStorage.getItem(paramsFocus))) {
        localStorage.setItem(paramsFocus, JSON.stringify(searchParams.get(paramsFocus)));
        sendRefDataTg(searchParams.get(paramsFocus));
      }
    }
  }, [location]);

  return null;
}
