export const sendMetrik = (type, value, onceType, sessionOnce) => {
  const isLocalHost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "";
  if (isLocalHost) return console.log("local host");
  if (onceType) {
    if (JSON.parse(localStorage.getItem(onceType))) {
      return;
    } else {
      localStorage.setItem(onceType, JSON.stringify(onceType));
    }
  }
  if (sessionOnce) {
    if (JSON.parse(sessionStorage.getItem(sessionOnce))) {
      return;
    } else {
      sessionStorage.setItem(sessionOnce, JSON.stringify(sessionOnce));
    }
  }
  window.ym(process.env.REACT_APP_Y_METRIKA_ID, type, value);
};
