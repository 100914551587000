export const SetPageDescription = (pageTitle, pageDescription, pageImage) => {
  const defaultTitle = "Орехи и сухофрукты в Москве и области";
  const defaultDescription = "Добро пожаловать в магазин к Белочке!";
  const defaultImage = "https://i.ibb.co/dbC2bg9/7.jpg";
  const descriptionFirstLine = pageDescription.split(".")[0];

  const changeTitle = (x) => {
    const documentTitle = document.querySelector("meta[property='og:title']");
    document.title = x ? x : defaultTitle;
    documentTitle.setAttribute("content", x ? x : defaultTitle);
  };
  const changeDescription = (x) => {
    const documentDescription = document.querySelector("meta[name='description']");
    const documentDescriptionOg = document.querySelector("meta[property='og:description']");
    documentDescription.setAttribute("content", x ? x : defaultDescription);
    documentDescriptionOg.setAttribute("content", x ? x : defaultDescription);
  };
  const changeImageOg = (x) => {
    const documentImageOg = document.querySelector("meta[property='og:image']");
    documentImageOg.setAttribute("content", x ? x : defaultImage);
  };

  return pageTitle && changeTitle(pageTitle), pageDescription && changeDescription(descriptionFirstLine), pageImage && changeImageOg(pageImage);
};
