import { Outlet } from "react-router-dom";
import styled from "styled-components";
import NewYearImg from "../../assets/images/decoration/newYear.png";
import NewYearChineseImg from "../../assets/images/decoration/newYearChinese.png";

function Layout() {
  const listThemeDecoration = [
    { name: "newYear", img: NewYearImg },
    { name: "newYearChinese", img: NewYearChineseImg },
  ];
  const activeThemeDecoration = 1;

  return (
    <LayoutWrap>
      <Outlet />
      {/* <Decoration imgLink={listThemeDecoration[activeThemeDecoration].img} className={listThemeDecoration[activeThemeDecoration].name} /> */}
    </LayoutWrap>
  );
}

export default Layout;

const LayoutWrap = styled.div`
  width: min(100%, 1250px);
  padding: 0 16px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);
  // - header height
`;

const Decoration = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  background-image: ${(props) => `url(${props.imgLink})`};
  filter: opacity(0.2) blur(0.2px);
  z-index: -1;
`;
