import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ImageFullScreen = (props) => {
  const [fullScreen, setFullScreen] = useState(false);

  // useEffect(() => {
  //   if (fullScreen) {
  //     document.body.classList.add("no-scroll");
  //   } else {
  //     document.body.classList.remove("no-scroll");
  //   }
  // }, [fullScreen]);
  return (
    <FullImageWrap imgLink={props.dataImg} className={fullScreen && "fullScreen"}>
      {fullScreen && (
        <CloseBtn
          onClick={() => {
            setFullScreen(false);
          }}
        />
      )}
      <img
        src={props.dataImg}
        alt={props.dataName}
        onClick={() => {
          setFullScreen(!fullScreen);
        }}
      />
    </FullImageWrap>
  );
};

export default ImageFullScreen;

const FullImageWrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  &.fullScreen {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    border-radius: unset;
    z-index: 10;
    overflow: auto;
    /* Bg cover image */
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: ${(props) => `url(${props.imgLink})` || "#c8dad1;"} center center no-repeat;
      background-size: cover;
      z-index: -1;
    }
    /* Blur for bg */
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(5px);
    }
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    z-index: 2;
    cursor: pointer;
  }
`;

const CloseBtn = styled.span`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
  transition: opacity 0.3s ease-in-out;

  &:after,
  &:before {
    content: "";
    height: 20px;
    width: 20px;
    border-top: 2px solid #cacdcc;
    position: absolute;
    top: 7px;
    right: -8px;
    transform: rotate(-45deg);
  }

  &:before {
    right: 6px;
    transform: rotate(45deg);
  }

  &:hover {
    opacity: 0.3;
  }
`;
