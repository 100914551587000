import React, { useEffect, useState } from "react";
import Banner from "./Banner/Banner";
import s from "./styles.module.scss";
import ImgTopProduct from "../../assets/images/home/topProduct.png";
import ImgFeaturePrice from "../../assets/images/home/featurePrice.png";
import ImgDelivery from "../../assets/images/menu/delivery-icon.png";
import ImgFeatureMoney from "../../assets/images/home/featureMoney.png";
import ButtonBig from "../../components/ButtonBig/ButtonBig";

import ReviewsSlide1 from "../../assets/images/home/reviews/slide1.jpg";
import ReviewsSlide2 from "../../assets/images/home/reviews/slide2.jpg";
import ReviewsSlide3 from "../../assets/images/home/reviews/slide3.jpg";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Link } from "react-router-dom";
import categoriesInfo from "../../assets/categoriesInfo.json";
import ImageFullScreen from "../../components/ImageFullScreen/ImageFullScreen";
import { sendMetrik } from "../../helpers/YaMetrics";

const Home = () => {
  const popularContentMobile = useMediaQuery("(min-width: 694px)");
  return (
    <main className={s.main}>
      <Banner />
      <section className={s.advanteges}>
        <h2>
          Наши <span className="green">преимущества</span>
        </h2>
        <div className={s.content}>
          <Link to="/catalog">
            <div className={`${s.block}`}>
              <div className={s.text}>
                <h3>Лучшая цена </h3>
                <p>Наши цены одни из самых лучших на рынке*</p>
              </div>
              <img src={ImgFeaturePrice} alt="price" />
            </div>
          </Link>
          <Link to="/delivery">
            <div className={`${s.block}`}>
              <div className={s.text}>
                <h3>Бесплатная доставка</h3>
                <p>При заказе от 1500 рублей - привезем товар бесплатно*</p>
              </div>
              <img src={ImgDelivery} alt="delivery" />
            </div>
          </Link>
          <Link to="/catalog">
            <div className={`${s.block}`}>
              <div className={s.text}>
                <h3>Отборные продукты</h3>
                <p>Вся наша продукция исключительно свежая</p>
              </div>
              <img src={ImgTopProduct} alt="catalog" />
            </div>
          </Link>
          <Link to="/delivery">
            <div className={`${s.block}`}>
              <div className={s.text}>
                <h3>Оплата при получении</h3>
                <p>Никаких предоплат, деньги при получении товара</p>
              </div>
              <img src={ImgFeatureMoney} alt="moneySafe" />
            </div>
          </Link>
        </div>
      </section>
      <section className={s.popular} id="popularsCategory">
        <h2>
          Популярные <span className="green">категории</span>{" "}
        </h2>
        <div className={`${s.popular__content} ${popularContentMobile && s.desktop}`}>
          <div className={`${s.block} ${s.nuts} ${!popularContentMobile && s.bigHeight}`}>
            <Link to="/catalog/nuts">
              <h3>{categoriesInfo[0].title}</h3>
              <p>{categoriesInfo[0].text}</p>
            </Link>
          </div>

          <div className={`${s.block} ${s.dry}`}>
            <Link to="/catalog/dry">
              <h3>{categoriesInfo[1].title}</h3>
              <p>{categoriesInfo[1].text}</p>
            </Link>
          </div>

          <div className={`${s.block} ${s.sweets}`}>
            <Link to="/catalog/sweets">
              <h3>{categoriesInfo[2].title}</h3>
              <p>{categoriesInfo[2].text}</p>
            </Link>
          </div>

          <div className={`${s.block} ${s.succade} ${!popularContentMobile && s.bigWidth}`}>
            <Link to="/catalog/succade">
              <h3>{categoriesInfo[3].title}</h3>
              <p>{categoriesInfo[3].text}</p>
            </Link>
          </div>

          <div className={`${s.block} ${s.sets} ${!popularContentMobile && s.bigWidth}`}>
            <Link to="/catalog/sets">
              <h3>{categoriesInfo[4].title}</h3>
              <p>{categoriesInfo[4].text}</p>
            </Link>
          </div>
        </div>

        <ButtonBig>
          <Link to="/catalog">Перейти в каталог</Link>
        </ButtonBig>
      </section>
      <section className={s.reviews}>
        <h2>
          Вот, что <span className="green">пишут покупатели</span>
        </h2>
        <p>
          «Если заказывать орехи, то только у вас» <br /> <span className="green">- мнение клиента бесценно!</span>
        </p>
        <div className={s.reviews__slider}>
          <div className={s.reviews__image} onClick={()=>{sendMetrik("reachGoal", "btn-feedback", "feedback");}}>
            <ImageFullScreen dataImg={ReviewsSlide1} dataName={"Отзыв 1"} />
          </div>
          <div className={s.reviews__image} onClick={()=>{sendMetrik("reachGoal", "btn-feedback", "feedback");}}>
            <ImageFullScreen dataImg={ReviewsSlide2} dataName={"Отзыв 2"}/>
          </div>
          <div className={s.reviews__image} onClick={()=>{sendMetrik("reachGoal", "btn-feedback", "feedback");}}>
            <ImageFullScreen dataImg={ReviewsSlide3} dataName={"Отзыв 3"}/>
          </div>
        </div>
      </section>
      <a href="https://t.me/Schummik" target="_blank" rel="noopener noreferrer" className={s.creator}>
        Сайт создан @Shummik
      </a>
    </main>
  );
};

export default Home;
