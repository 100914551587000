import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import s from "./styles.module.scss";
import BasketContext from "../../helpers/Contexts/basketContext";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import TrashImg from "../../assets/images/basket/trash.png";
import BannerImg from "../../assets/images/home/banner-img.png";
import inputNameIcon from "../../assets/images/basket/inputNameIcon.png";
import inputPhoneIcon from "../../assets/images/basket/inputPhoneIcon.png";
import inputLocationIcon from "../../assets/images/basket/inputLocationIcon.png";
import { Link } from "react-router-dom";
import ButtonBig from "../../components/ButtonBig/ButtonBig";
import InputMask from "react-input-mask";
import useOnClickOutside from "../../hooks/useClickOutside";
import { sendMetrik } from "../../helpers/YaMetrics";
import deliveryAreas from "../../assets/deliveryAreas.json";
import promocodesList from "../../assets/promocodesList.json";
import useLocalHost from "../../hooks/useLocalHost";
import UserInfoContext from "../../helpers/Contexts/userInfoContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import referrersList from "../../assets/referrersList.json";

const paymentBase = ["Переводом на карту /СБП", "Наличными"];

const Basket = () => {
  const { basket } = useContext(BasketContext);
  const { userInfo } = useContext(UserInfoContext);
  const [location, setLocation] = useState(-1);
  const [payment, setPayment] = useState(0);
  const [orderSend, setOrderSend] = useState(false);
  const [orderNumber, setOrderNumber] = useState(0);
  const [basketPrice, setBasketPrice] = useState(0);
  const [promoStatus, setPromoStatus] = useState(-1);
  const [referralUser, setReferralUser] = useLocalStorage("ref", "");
  useEffect(() => {
    setBasketPrice(basket.reduce((total, cur) => total + cur.itemPrice, 0));
  }, [basket]);

  useEffect(() => {
    setLocation(userInfo.location);
  }, [userInfo.location]);

  useEffect(() => {
    setPayment(userInfo.payment);
  }, [userInfo.payment]);

  return (
    <main className={s.basket}>
      <BreadCrumbs />
      <h2 className={s.title}>Корзина товаров</h2>
      {basket.length > 0 ? (
        <div className={s.content}>
          <div className={s.basketList}>
            {basket.map((item, index) => (
              <GoodsBlock key={index} good={item} />
            ))}
            <p className={s.info}>
              <span>Не нашли нужный вам товар? </span>
              <a
                href="https://t.me/+79933382402"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  sendMetrik("reachGoal", "btn-goods-need", "", "findGoods");
                }}
              >
                Напишите&nbsp;нам
              </a>{" "}
              и мы его добавим
            </p>
          </div>
          <PaymentBlock payment={payment} setPayment={setPayment} />
          <DeliveryBlock promoStatus={promoStatus} location={location} setLocation={setLocation} />
          <div className={s.mail}>
            <p>
              <span className={s.text}>Товаров на: </span>
              <span className="green">{basketPrice}&nbsp;</span> руб.
            </p>
            <p>
              <span className={s.text}>Доставка: </span>
              <span className={location >= 0 ? "green" : "red"}>
                {location >= 0
                  ? deliveryAreas[location].price.free > basketPrice
                    ? deliveryAreas[location].price.delivery + "руб."
                    : "бесплатно"
                  : "Выберите район"}
                &nbsp;
              </span>
            </p>
            {location >= 0 && deliveryAreas[location].price.free > basketPrice && (
              <p className={s.hint}>
                <span className={s.text}>До бесплатной доставки осталось: </span>
                <span>{deliveryAreas[location].price.free - basketPrice} &nbsp;</span>
                руб.
              </p>
            )}
            <p>
              <span className={`${s.text} ${s.final}`}>Итого:</span>
              <span className={`${location >= 0 ? s.green : s.red} ${s.final}`}>
                {location >= 0 && deliveryAreas[location].price.free > basketPrice ? basketPrice + deliveryAreas[location].price.delivery : basketPrice}&nbsp;
              </span>
              руб.
            </p>
            <SenderOrder
              basket={basket}
              location={location}
              setLocation={setLocation}
              payment={payment}
              orderNumber={orderNumber}
              setOrderNumber={setOrderNumber}
              promoStatus={promoStatus}
              referral={referralUser}
              setPromoStatus={setPromoStatus}
              statusOrder={setOrderSend}
            />
          </div>
        </div>
      ) : (
        <div className={s.basketList}>
          <p>
            <Link to="/catalog">У вас еще нет товаров</Link>
          </p>
        </div>
      )}
      {orderSend && <ThanksScreen orderNumber={orderNumber} />}
    </main>
  );
};

const PaymentBlock = (props) => {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const handlePaymentChange = (e) => {
    const resultPayment = +e.target.id.slice(-1);
    props.setPayment(resultPayment);
    setUserInfo({ ...userInfo, payment: resultPayment });
  };

  return (
    <div className={`${s.contentBlock} ${s.payment}`}>
      <h3>Выберите способ оплаты</h3>
      {paymentBase.map((item, index) => (
        <PaymentRadioBtn key={index} number={index} pickedPayment={props.payment} onChange={handlePaymentChange} />
      ))}
      <p className={s.info}>Оплата производится при получении товара</p>
    </div>
  );
};

const DeliveryBlock = (props) => {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const { promoStatus, location, setLocation } = props;
  const handleChange = (e) => {
    const resultLocation = +e.target.id.slice(-1);
    setLocation(resultLocation);
    setUserInfo({ ...userInfo, location: resultLocation });
  };
  return (
    <div className={`${s.contentBlock} ${s.deliver}y`}>
      <h3>Выберите район доставки</h3>
      {promoStatus < 0 ? (
        <>
          <input type="radio" name="location" readOnly checked={location === -1} style={{ display: "none" }} />
          {deliveryAreas
            .filter((area) => area.price.delivery > 0)
            .map((item, index) => (
              <DeliveryRadioBtn key={index} number={index} pickedLocation={location} onChange={handleChange} />
            ))}
          <Link to="/delivery">
            <p className={s.aboutBtn}>Читать подробнее про доставку</p>
          </Link>
        </>
      ) : (
        <DeliveryRadioBtn number={location} pickedLocation={location} onChange={handleChange} />
      )}
      <p className={s.info}>Доставка осуществляется в течении 1-2 дней с момента заказа</p>
    </div>
  );
};

const GoodsBlock = (props) => {
  const { basket, setBasket } = useContext(BasketContext);
  const [trashActive, setTrashActive] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setTrashActive(false));

  const removeGood = (goodToRemove) => {
    setBasket(basket.filter((good) => good !== goodToRemove));
    setTrashActive(false);
  };
  return (
    <div ref={ref} className={`${s.block} ${trashActive && s.active}`}>
      <Link key={props.good.itemId} to={`/item/${props.good.itemId}`}>
        <div className={s.blockInfo}>
          <p className={s.title}>{props.good.itemName}</p>
          <div className={s.numbers}>
            <p className={s.weight}>
              {props.good.itemWeight} {props.good.itemWeight < 20 ? <span> шт.</span> : <span> г.</span>}
            </p>
            <p className={s.price}>{props.good.itemPrice} руб.</p>
          </div>
        </div>
      </Link>
      <div
        className={s.blockDelBtn}
        onClick={() => {
          trashActive ? removeGood(props.good) : setTrashActive(true);
        }}
      >
        <img src={TrashImg} alt="Корзина" />
      </div>
    </div>
  );
};

const DeliveryRadioBtn = ({ number, pickedLocation, onChange }) => {
  return (
    <div className={s.deliveryLocation}>
      <input id={`loc${number}`} type="radio" name="location" onChange={onChange} checked={pickedLocation === number} />
      <label htmlFor={`loc${number}`}>
        <span className={s.dotWrap}></span> {deliveryAreas[number].name}
      </label>
    </div>
  );
};

const PaymentRadioBtn = ({ number, pickedPayment, onChange }) => {
  return (
    <div className={s.deliveryLocation}>
      <input id={`pay${number}`} type="radio" name="payment" onChange={onChange} checked={pickedPayment === number} />
      <label htmlFor={`pay${number}`}>
        <span></span> {paymentBase[number]}
      </label>
    </div>
  );
};

const SenderOrder = (props) => {
  const { setBasket } = useContext(BasketContext);
  const notLocalHost = !useLocalHost();
  // true for test, false for prod;
  const testTg = false;
  const telegram = {
    token: testTg ? process.env.REACT_APP_TG_TEST_CHAT_TOKEN : process.env.REACT_APP_TG_CHAT_TOKEN,
    chat: testTg ? process.env.REACT_APP_TG_TEST_CHAT_NUMBER : process.env.REACT_APP_TG_CHAT_NUMBER,
  };

  const confirmOrder = (basket, location, payment, userData, referral) => {
    const basketPrice = basket.reduce((total, cur) => total + cur.itemPrice, 0);
    const basketWeight = basket.reduce((total, cur) => total + cur.itemWeight, 0);
    const deliveryPrice = deliveryAreas[location].price.free > basketPrice ? deliveryAreas[location].price.delivery : 0;
    const confirmOrderNumber = Math.floor(Math.random() * 10000);
    const referralIndex = referrersList.findIndex((ref) => ref.idLink == props.referral);

    const getResultMessage = () => {
      let message = `Поступил заказ(№${confirmOrderNumber}) \%0A`;
      message += `от ${userData.userName} номер ${userData.userPhone} \%0A`;
      if (props.referral) message += `Данный заказчик скорее всего от реферала: ${referrersList[referralIndex].name}\%0A`;
      if (props.promoStatus >= 0) message += `отдел ${userData.userWork}`;
      message += `\%0A `;
      message += `в районе ${deliveryAreas[location].name} (Точный адресс: ${userData.userLocation.length > 0 ? userData.userLocation : "Не указан"}) на: \%0A`;
      message += `\%0A `;
      basket.map((item, index) => {
        message += `${index + 1}) ${item.itemName}, ${item.itemWeight} ${item.itemWeight < 20 ? "шт." : "г."} : ${item.itemPrice} руб. \%0A \%0A`;
      });
      message += `Способ оплаты: ${paymentBase[payment]} \%0A`;
      message += `\%0A `;
      message += `Общим весом: ${basketWeight / 1000} кг\%0A `;
      message += `Итого: ${deliveryPrice + basketPrice} руб.(Товар ${basketPrice} руб,  Доставка ${deliveryPrice} руб)`;
      return message;
    };

    const sendToMockApi = (data) => {
      const messageToMockApi = {
        message: data,
      };
      fetch("https://63fb9b597a045e192b6c5006.mockapi.io/order", {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(messageToMockApi),
      });
    };
    if (!testTg) sendToMockApi(getResultMessage());

    fetch(`https://api.telegram.org/bot${telegram.token}/sendMessage?chat_id=${telegram.chat}&text=${getResultMessage()}`, {
      method: "GET",
    }).then(
      (success) => {
        console.log(success, "success");
        props.setOrderNumber(confirmOrderNumber);
        props.statusOrder(true);
        sendMetrik("reachGoal", "btn-order-submit", "", "order-once");
        window._tmr.push({ type: "reachGoal", id: 3334148, goal: "orderToTg" });
        setBasket([]);
      },
      (error) => {
        console.log(error, "eror");
      }
    );
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [formData, setFormData] = useState("");
  const [formValidate, setFormValidate] = useState(true);

  const onSubmit = (data) => {
    const checkEmptyBasket = props.basket.length > 0;
    const checkPhoneUnderscore = !data.userPhone.includes("_");
    const checkLocationPicked = props.location >= 0;
    if (checkEmptyBasket && checkPhoneUnderscore && checkLocationPicked) {
      setFormData(JSON.stringify(data));
      setFormValidate(true);
      confirmOrder(props.basket, props.location, props.payment, data);
    } else {
      setFormValidate(false);
    }
  };

  return (
    <>
      <form id="orderForm" onSubmit={handleSubmit(onSubmit)}>
        <div className={s.inputWrap}>
          <input
            {...register("userName", { required: true })}
            aria-invalid={errors.userName ? "true" : "false"}
            className={`${s.mailInput} ${errors.userName?.type === "required" && s.invalid}`}
            type="text"
            placeholder={props.promoStatus < 0 ? "Введите ваше имя" : "Введите Имя и Фамилию"}
          />
          <img src={inputNameIcon} alt="name" />
        </div>
        {/* {errors.userName?.type === "required" && (
          <p role="alert">First name is required</p>
        )} */}
        {props.promoStatus >= 0 && (
          <div className={s.inputWrap}>
            <input
              {...register("userWork", { required: true })}
              aria-invalid={errors.userWork ? "true" : "false"}
              className={`${s.mailInput} ${errors.userWork?.type === "required" && s.invalid}`}
              type="text"
              placeholder="Введите ваш отдел"
            />
            <img src={inputLocationIcon} alt="name" />
          </div>
        )}
        <div className={s.inputWrap}>
          <InputMask
            mask="+7 (999) 999-99-99"
            alwaysShowMask={true}
            {...register("userPhone", {
              required: true,
            })}
            aria-invalid={errors.userPhone ? "true" : "false"}
            className={`${s.mailInput} ${errors.userPhone?.type === "required" && s.invalid}`}
            type="tel"
          />
          <img src={inputPhoneIcon} alt="phone" />
        </div>
        {/* {errors.userPhone && <p role="alert">{errors.userPhone?.message}</p>} */}
        {props.promoStatus < 0 && (
          <div className={s.inputWrap}>
            <input
              {...register("userLocation", { required: true })}
              aria-invalid={errors.userLocation ? "true" : "false"}
              className={`${s.mailInput} ${errors.userLoction?.type === "required" && s.invalid}`}
              type="text"
              placeholder="Введите ваш адрес доставки"
            />
            <img src={inputLocationIcon} alt="location" />
          </div>
        )}
      </form>
      {props.promoStatus < 0 ? (
        <InputPromocode setPromoStatus={props.setPromoStatus} setLocation={props.setLocation} />
      ) : (
        <ActivePromocode promoStatus={props.promoStatus} />
      )}
      <ButtonBig type="submit" form="orderForm">
        Оформить заказ
      </ButtonBig>
    </>
  );
};

const ThanksScreen = (props) => {
  return (
    <div className={s.thanksWrap}>
      <div className={s.thanksContent}>
        <img src={BannerImg} alt="банер" />
        <h3>
          Спасибо за заказ: <span className="green">{props.orderNumber}</span>
        </h3>
        <p>Запомните этот номер, в ближайшее время с вами свяжется наш менеджер</p>
        <Link to="/">
          <ButtonBig center="true">Вернуться на главную</ButtonBig>
        </Link>
      </div>
    </div>
  );
};

const InputPromocode = (props) => {
  const checkPromo = (event) => {
    event.preventDefault();
    const promo = event.target[0].value.toLowerCase();
    const promoIndex = promocodesList.findIndex((code) => code.name.toLowerCase() === promo);
    if (promoIndex >= 0) {
      props.setPromoStatus(promoIndex);
      props.setLocation(promocodesList[promoIndex].deliveryAreasIndex);
    } else {
      props.setPromoStatus(-1);
    }
  };

  return (
    <form onSubmit={checkPromo}>
      <div className={`${s.inputWrap} ${s.promoWrap}`}>
        <input className={`${s.promoInput}`} type="text" id="promoCode" name="promoCode" placeholder="Введите промокод" />
        <button type="submit" className={s.promoBtn}>
          Применить
        </button>
      </div>
    </form>
  );
};

const ActivePromocode = (props) => {
  return (
    <div className={`${s.inputWrap} ${s.promoWrapActive}`}>
      <span className={s.promoName}>{promocodesList[props.promoStatus].name}</span>
      <span>код активен</span>
    </div>
  );
};

export default Basket;
